import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { withRouter, GLGlobal, GLUtil, connect, alignPop, PaginationParams } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { GSAdminLocale } from '@app/locales/localeid';
import { GSAdminAction, SessionStorageKeys, fmtMsg, CONSTS } from '@app/util';
import { StateType } from '@app/states';
import { getLocalProducts } from '@app/states/admin/product';
import { LocalProductModelProps } from '@app/service/admin/products';
import { ListingPage, IListingPageProps } from './components/listing';
import { getUnitTextForLocalProduct } from './components/unitprice';

@withRouter
@connect(({ product: { region, regions, localList: list, localTotal: total, localActiveCnt: activeCnt },
    addProductAction = GSAdminAction.CreateLocalProduct,
    paginationKey = SessionStorageKeys.LocalProductsPagination,
    listStoreKey = 'localproduct-list-states',
    listStateKey = 'localproduct-list-states' }: StateType) => ({
        region,
        regions,
        list,
        total,
        activeCnt,
        addProductAction,
        paginationKey,
        listStoreKey,
        listStateKey
    }), {
    getData: getLocalProducts
})
export class LocalProductPage extends Component<RouteComponentProps<any> & IListingPageProps> {

    getColumns() {
        return [
            {
                title: GSAdminLocale.RegionsModelName,
                dataIndex: LocalProductModelProps.regionEnglishName,
                width: '10%',
                render: (text, record, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListRegion)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Region, { regionId: record.regionId })}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className: 'navigation-col'
            },
            {
                title: GSAdminLocale.ProductName,
                dataIndex: LocalProductModelProps.name,
                width: '16%',
                render: (text, record, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.EditLocalProduct)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.LocalProductsEdit, { localProductId: record.id })}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className: 'navigation-col'
            },
            {
                title: GSAdminLocale.ProductStartUnit,
                dataIndex: LocalProductModelProps.startUnitText,
                width: '8%',
            },
            {
                title: GSAdminLocale.ProductEndUnit,
                dataIndex: LocalProductModelProps.endUnitText,
                width: '8%',
            },
            {
                title: GSAdminLocale.LocalProductGlobal,
                dataIndex: LocalProductModelProps.globalProductName,
                width: '8%', //'9%'
            },
            {
                title: GSAdminLocale.LocalProductTextbookSubscription,
                dataIndex: LocalProductModelProps.isTextSubscription,
                align: 'left',
                width: '14%',
                render: (text, record, index) => {
                    return record.isTextSubscription ? CONSTS.AntCheckBox2HtmlCheckedDisabled : CONSTS.AntCheckBox2HtmlUnCheckedDisabled;
                }
            },
            {
                title: GSAdminLocale.LocalProductDigitalSubscription,
                dataIndex: LocalProductModelProps.isDigitalSubscription,
                align: 'left',
                width: '12%',
                render: (text, record, index) => {
                    return record.isDigitalSubscription ? CONSTS.AntCheckBox2HtmlCheckedDisabled : CONSTS.AntCheckBox2HtmlUnCheckedDisabled;
                }
            },
            {
                title: GSAdminLocale.LocalProductIsPurchase,
                dataIndex: LocalProductModelProps.isPurchase,
                align: 'left',
                width: '8%',
                render: (text, record, index) => {
                    return record.isPurchase ? CONSTS.AntCheckBox2HtmlCheckedDisabled : CONSTS.AntCheckBox2HtmlUnCheckedDisabled;
                }
            }
        ];
    }

    render() {
        const { region, regions } = this.props;
        return (
            <ListingPage
                {...this.props}
                columns={this.getColumns()}
                canAdd={region && !region.disabled}
                addProductUri={PathConfig.LocalProductsCreate}
                pageTitle={GSAdminLocale.LocalProductListTitle}
                allowSorting={true}
                hasProductFilter={true}
                defaultSorter={{
                    columnName: LocalProductModelProps.regionEnglishName,
                    columnSortName: LocalProductModelProps.regionEnglishName,
                    ascending: true
                }}
            />
        )
    }
}
