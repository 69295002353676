import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Input } from 'antd-min';
import { PaginationParams, GLAction, GLGlobal } from 'gl-commonui';
import { ListStatesStore, ListStatesRadios, ListStatesPortal, MainTitle, Container } from '@app/components';
import { ListStates, SessionStorageKeys, ContextHelper, fmtMsg } from '@app/util';
import { RegionModel } from '@app/service/admin/regions/model';
import { GSAdminLocale } from '@app/locales/localeid';
import { GLGrid, GLGridSorter } from '@app/components/gl-grid';
import { getUnitTextForLocalProduct } from "@app/page/product/components/unitprice";

export interface IListingPageProps {
    pageTitle?: string
    region?: RegionModel
    regions?: RegionModel[]
    canAdd?: boolean
    addProductAction: string
    addProductUri: string
    paginationKey: SessionStorageKeys
    listStoreKey: string
    listStateKey: string
    list: any
    columns: any
    total: number
    activeCnt: number
    hasProductFilter?: boolean
    allowSorting?: boolean
    defaultSorter?: GLGridSorter
    columnSortingMap?: Map<string, string>
    getData: (params) => void
    onFilterChanged?: (filter) => void
}
export interface IListingPageStates {
    loading?: boolean
    listState: ListStates
}

export class ListingPage extends Component<IListingPageProps, IListingPageStates> {
    listStateStore: ListStatesStore
    tableProps: any = {
        pagination: new PaginationParams(1, 20),
        rowClassName: (record) => record.disabled ? "list-disabled" : "",
        onChange: this.handleTablePageChange.bind(this)
    }
    sorter: GLGridSorter
    filter: string

    constructor(props) {
        super(props);
        this.handleListStateChanged = this.handleListStateChanged.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onColumnSorting = this.onColumnSorting.bind(this);
        this.listStateStore = new ListStatesStore(props.listStoreKey, props.listStateKey);
        this.state = {
            listState: this.listStateStore.getState()
        };
        this.sorter = props.defaultSorter;
        this.filter = '';
    }

    componentDidMount() {
        this.loadListingData(this.props.defaultSorter, this.filter);
    }

    loadListingData(sorter: GLGridSorter, filter?: string) {
        this.tableProps.pagination.current = ContextHelper.getSessionPagination(this.props.paginationKey).current;
        const params = this.listStateStore.getUrlQuery(this.tableProps.pagination.toRequest());
        this.props.getData({
            query: {
                ...params,
                filterText: filter,
                sortBy: sorter ? sorter.columnSortName : null,
                isDescending: sorter &&  sorter.columnSortName? !sorter.ascending  : null
            }
        });
    }

    handleListStateChanged(state) {
        this.setState({ listState: state });
        this.listStateStore.setState(state);
        (this.tableProps.pagination as PaginationParams).current = 1;
        this.handleTablePageChange(this.tableProps.pagination, null, null);
    }

    handleTablePageChange(pagination, filters, sorter) {
        this.tableProps.pagination.current = pagination.current;
        ContextHelper.setSessionPagination(this.props.paginationKey, pagination);
        this.loadListingData(this.sorter, this.filter);
    }

    onSearch(value) {
        this.filter = value;
        (this.tableProps.pagination as PaginationParams).current = 1;
        ContextHelper.setSessionPagination(this.props.paginationKey, this.tableProps.pagination);
        this.loadListingData(this.sorter, value);
    }

    onColumnSorting(sorter: GLGridSorter) {
        const {columnSortingMap} = this.props;
        const columnName = columnSortingMap && columnSortingMap.has(sorter.columnSortName) ? columnSortingMap.get(sorter.columnSortName) : sorter.columnSortName;
        this.sorter = {...sorter, columnSortName: columnName };
        this.loadListingData(this.sorter, this.filter);
    }

    renderProductFilter() {
        return (
            <div className='region-filter'>
                <Input.Search
                    placeholder={fmtMsg({id: GSAdminLocale.LocalProductListSearchPlaceHolder})}
                    onSearch={this.onSearch}
                />
            </div>
        )
    }

    formatData(records) {
        return records.map(record => ({
            ...record,
            startUnitText: record.startUnit ? parseInt(record.startUnit, 10) > 0 ? getUnitTextForLocalProduct(parseInt(record.startUnit, 10), false) : getUnitTextForLocalProduct(Math.abs(parseInt(record.startUnit, 10)), true) : '',
            endUnitText: record.endUnit ? parseInt(record.endUnit, 10) > 0 ? getUnitTextForLocalProduct(parseInt(record.endUnit, 10), false) : getUnitTextForLocalProduct(Math.abs(parseInt(record.endUnit, 10)), true) : ''
        }))
    }

    render() {
        const  {pageTitle, canAdd, addProductAction, addProductUri, list: dataSource, columns, total, activeCnt, allowSorting, hasProductFilter, defaultSorter } = this.props;
        this.tableProps.pagination.total = ListStatesStore.getStateCnt(total, activeCnt, this.state);
        const updatedTableProps = { dataSource: this.formatData(dataSource), columns, pagination: this.tableProps.pagination, rowClassName: this.tableProps.rowClassName };
        const mergedTableProps = {...this.tableProps, ...updatedTableProps};
        return (
            <Container fullWidth className="product-list">
                <MainTitle title={ pageTitle || GSAdminLocale.LocalProductListTitle } />
                <div className='page-content'>
                    {hasProductFilter && this.renderProductFilter()}
                    <div className='product-list-states'>
                        <ListStatesPortal selector=".product-list-states">
                            <ListStatesRadios
                                allCnt={total}
                                activeCnt={activeCnt}
                                value={this.state.listState}
                                onChange={this.handleListStateChanged}
                            />
                            {canAdd &&
                                <GLAction action={addProductAction}>
                                    <div className="content-header">
                                        <Link to={{ pathname: addProductUri }} >
                                            <Icon type="plus-circle-o" />
                                            <span>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.LocalProductListNew })}</span>
                                        </Link>
                                    </div>
                                </GLAction>
                            }
                        </ListStatesPortal>
                    </div>
                    <GLGrid
                        allowSorting = {allowSorting}
                        defaultSorter = {defaultSorter}
                        onColumnSorting = {this.onColumnSorting}
                        {...mergedTableProps}
                        loading = {false}
                    />
                </div>
            </Container>
        )
    }
}
